import * as React from 'react';
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';


import CurrentPrice from './CurrentPrice';



export default function MainGrid() {
  return (
    <Box sx={{ width: '100%', maxWidth: { sm: '100%', md: '1700px' }, display: 'flex', justifyContent: 'center',}}>

      <Grid
        container
        spacing={3}
        columns={12}
        sx={{ mb: (theme) => theme.spacing(2) }} >
        <Grid item={{ xs: 12, sm: 6, lg: 3 }}>
        </Grid>
        <Grid item={{ sm: 12, md: 6 }}>
          <CurrentPrice />
        </Grid>

      </Grid>
    </Box>
  );
}