import { AppBar, Toolbar, IconButton, Typography, Stack, Button } from "@mui/material";
import MyAboutButton from "./MyAboutButton";
import SubscribeButton from "./SubscribeButton";

export const NavBar = () => {
  return (
    <AppBar color="white" position="static">
      <Toolbar >
        <IconButton edge="start" color="inherit" aria-label="logo">
          {<img src={ "/barsicon.png" } alt="logo" />}
        </IconButton>
        <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
          GoldCoin Index
          </Typography>
          <Stack direction='row' spacing={2}>
            <Button color="inherit">Home</Button>
            {/* <Button color="inherit">Subscribe</Button> */}
            <SubscribeButton />
            <MyAboutButton />
          </Stack>
      </Toolbar>
    </AppBar>
  )
}