import './App.css';
import { GciBottomNavigation } from './components/BottomNavigation';
import { NavBar } from './components/navBar';
import MainGrid from './components/MainGrid';
import { Grid2, useMediaQuery } from '@mui/material';
import Copyright from './components/Copyright';
import { useTheme } from '@mui/material/styles';

function App() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Detect if it's a mobile screen

  return (
    <Grid2
      container
      direction="column"
      spacing={2}
      sx={{
        minHeight: '100vh',
        padding: isMobile ? '10px' : '20px', // Adjust padding for mobile
      }}
    >
      <Grid2 item xs={12}>
        <NavBar />
      </Grid2>

      {/* Ensure MainGrid grows to fill available space */}
      <Grid2 item xs={12} flexGrow={1}>
        <MainGrid />
      </Grid2>

      {/* Restore Bottom Navigation for mobile use */}
      {/* <Grid2 item xs={12}>
        <GciBottomNavigation />
      </Grid2> */}

      {/* Keep Copyright at the bottom */}
      <Grid2 item xs={12}>
        <Copyright />
      </Grid2>
    </Grid2>
  );
}

export default App;
