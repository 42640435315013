



import React from 'react';
import { Box, Typography, Grid, Card, CardContent } from '@mui/material';

function PriceDisplay({ metals, currencies }) {
  return (
    <Box sx={{ width: '100%', maxWidth: { sm: '100%', md: '1700px' }, mx: 'auto', p: 3 }}>
      {/* Title */}
      <Typography align='center' component="h2" variant="h3" sx={{ mb: 4 }}>
        Today's Gold Rates
      </Typography>

      {/* Grid Layout for Gold USD Prices */}
      <Grid container spacing={3} columns={12}>
        <Grid item xs={12} sm={6}>
          <Card elevation={3}>
            <CardContent>
              <Typography variant="h6" align="center">Gold AM (USD)</Typography>
              <Typography variant="body1" align="center">
                {metals?.lbma_gold_am ? `$${Intl.NumberFormat().format(metals.lbma_gold_am.toFixed(2))}` : 'N/A'}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Card elevation={3}>
            <CardContent>
              <Typography variant="h6" align="center">Gold PM (USD)</Typography>
              <Typography variant="body1" align="center">
                {metals?.lbma_gold_pm ? `$${Intl.NumberFormat().format(metals.lbma_gold_pm.toFixed(2))}` : 'N/A'}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Grid Layout for Gold Prices in GHS */}
      <Grid container spacing={3} sx={{ mt: 4 }}>
        {/* Full Troy Ounce Price */}
        <Grid item xs={12} sm={6} lg={4}>
          <Card elevation={3}>
            <CardContent>
              <Typography variant="h6" align="center">1 oz Gold (GHS)</Typography>
              <Typography variant="body1" align="center">
                {metals?.lbma_gold_pm && currencies?.GHS
                  ? `₵${Intl.NumberFormat().format((metals.lbma_gold_pm / currencies.GHS).toFixed(2))}`
                  : 'N/A'}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Half Troy Ounce Price */}
        <Grid item xs={12} sm={6} lg={4}>
          <Card elevation={3}>
            <CardContent>
              <Typography variant="h6" align="center">½ oz Gold (GHS)</Typography>
              <Typography variant="body1" align="center">
                {metals?.lbma_gold_pm && currencies?.GHS
                  ? `₵${Intl.NumberFormat().format(((metals.lbma_gold_pm / 2) / currencies.GHS).toFixed(2))}`
                  : 'N/A'}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Quarter Troy Ounce Price */}
        <Grid item xs={12} sm={6} lg={4}>
          <Card elevation={3}>
            <CardContent>
              <Typography variant="h6" align="center">¼ oz Gold (GHS)</Typography>
              <Typography variant="body1" align="center">
                {metals?.lbma_gold_pm && currencies?.GHS
                  ? `₵${Intl.NumberFormat().format(((metals.lbma_gold_pm / 4) / currencies.GHS).toFixed(2))}`
                  : 'N/A'}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

    </Box>
  );
}

export default PriceDisplay;