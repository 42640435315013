import { Button, Tooltip } from '@mui/material';

function SubscribeButton() {
  return (
    <Tooltip title="Coming soon!" arrow>
      <span> {/* Wrapping with a span to ensure tooltip works on disabled button */}
        <Button color="inherit" disabled>
          Subscribe
        </Button>
      </span>
    </Tooltip>
  );
}

export default SubscribeButton;