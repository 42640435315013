import { BottomNavigation, BottomNavigationAction } from "@mui/material";
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import { useState } from "react";

export const GciBottomNavigation = () => {
  const [value, setValue] = useState(0);

  return (
    <BottomNavigation
      sx={{ width: "100%", position: "absolute", bottom: 30 }}
      value={value}
      onChange={(event, newValue) => setValue(newValue)}
    >
      <BottomNavigationAction label='Home' icon={<HomeIcon />} />

      {/* Profile link wrapped around BottomNavigationAction */}
      <BottomNavigationAction
        component="a"
        href="https://github.com/niialabi"
        target="_blank"
        rel="noopener noreferrer"
        label="Profile"
        icon={<PersonIcon />}
      />

      {/* Add other BottomNavigationActions as needed */}
    </BottomNavigation>
  );
}
