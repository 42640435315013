import React from 'react';
import { Button } from '@mui/material';

function MyAboutButton() {
  return (
    <Button
      color="inherit"
      component="a"
      href="https://github.com/niialabi"
      target="_blank"
      rel="noopener noreferrer"
    >
      About
    </Button>
  );
}

export default MyAboutButton;
