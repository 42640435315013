import { Typography, Link as MuiLink } from '@mui/material';

export default function Copyright() {
  return (
    <Typography
      variant="body2"
      align="center"
      sx={{
        color: 'text.secondary',
        mt: 'auto', // Pushes the component to the bottom
        py: 2, // Adds some padding to avoid it sticking to the bottom too closely
      }}
    >
      {'Copyright © '}
      <MuiLink color="inherit" href="https://github.com/niialabi">
        Empire State of Mind
      </MuiLink>{' '}
      {new Date().getFullYear()}.
    </Typography>
  );
}
