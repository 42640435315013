import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import PricesDisplay from './PriceDisplay';

const CurrentPrice = () => {
    const [prices, setPrices] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                // const response = await fetch('http://localhost:3001/api');
                const response = await fetch('/goldcedi-index-backend/api' || 'http://localhost:3001/api');
                const data = await response.json();

                setPrices({
                    metals: {
                        lbma_gold_am: data.lbma_gold_am,
                        lbma_gold_pm: data.lbma_gold_pm
                    },
                    currencies: {
                        GHS: data.GHS
                    },
                    top5: data.top5
                });
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    if (loading) {
        return (
            <Box
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}
            >
                <CircularProgress />
            </Box>
        );
    }

    return (
        <PricesDisplay 
            metals={prices?.metals} 
            currencies={prices?.currencies}
            top5={prices?.top5}
        />
    );
};

export default CurrentPrice;













// import React, { useState, useEffect } from 'react';
// import { Box } from '@mui/material';
// import CircularProgress from '@mui/material/CircularProgress';
// import PricesDisplay from './PriceDisplay';

// const CurrentPrice = () => {
//     const [metals, setMetals] = useState(null);
//     const [currencies, setCurrencies] = useState(null);
//     const [loading, setLoading] = useState(true);

//     useEffect(() => {
//         const fetchData = async () => {
//             try {
//                 // const response = await fetch('/goldcedi-index-backend/api' || 'http://localhost:3001/api');
//                 const response = await fetch('http://localhost:3001/api');
//                 // const response = await fetch('http://localhost:3001/api');
//                 const data = await response.json();

//                 setMetals(data.metalsData[0]); // Assuming you're interested in the first object
//                 setCurrencies(data.currenciesData[0]); // Assuming you're interested in the first object
//             } catch (error) {
//                 console.error('Error fetching data:', error);
//             } finally {
//                 setLoading(false);
//             }
//         };

//         fetchData();
//     }, []);

//     if (loading) {
//         return (
//             <Box
//                 sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}
//             >
//                 <CircularProgress />
//             </Box>
//         );
//     }

//     // if (!metals || !currencies) {
//     //     return <div>No data available</div>;
//     // }

//     return (
//         <PricesDisplay metals={metals} currencies={currencies}  />
//     );
// };

// export default CurrentPrice;
